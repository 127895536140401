import { set, ref } from 'firebase/database'
import shuffle from 'lodash.shuffle'
import { db } from '../firebase';
import { IRoom } from '../helpers/types';
import * as C from '../helpers/constants';

export const createRoomReq = (roomID: number, userID: string) => (
    set(ref(db, `/rooms/${roomID}`), {
        id: roomID,
        hostUserID: userID,
        usersIn: [],
    })
)

export const joinRoomReq = (rooms: IRoom[], roomID: number, userID: string, userName: string) => (
    set(ref(db, `/rooms/${roomID}/usersIn`), [
        ...(rooms[roomID]?.usersIn) || [],
        {
            id: userID,
            name: userName,
            avatarID: Math.floor(Math.random() * (10 - 0) + 0),
            points: 0,
            ownTraps: shuffle(Object.keys(C.trapList)).splice(0, 3),
        }
    ])
)
