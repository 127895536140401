/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ICategories, IRoom } from '../../../helpers/types'
import useUserAnswerPoints from '../../../helpers/useUserAnswerPoints';
import FinalScoreBoard from '../../../components/FinalScoreBoard/FinalScoreBoard';
import RightSide from './components/RightSide/RightSide';
import LeftSide from './components/LeftSide/LeftSide';
import Time from '../../../components/Time/Time'
import * as API from '../../../api/roomAPI';
import './styles.scss'

interface HostViewProps {
    room?: IRoom;
    categories?: ICategories[];
}

const HostView: React.FC<HostViewProps> = ({ room, categories }) => {
    const { ID: roomID } = useParams() as { ID: string };

    const { userWithPoints } = useUserAnswerPoints(roomID, room);

    // On finish question:
    useEffect(() => {
        if (room?.currentQuestion?.isFinished) {
            const pushNextQuestionTimer = setTimeout(() => {
                API.pushPointsToUsersReq(room!, roomID!, userWithPoints);
                API.pushQuestionReq(roomID, room);
            }, 10000);
            return () => { clearTimeout(pushNextQuestionTimer) };
        }
    }, [room?.currentQuestion?.isFinished])

    const onTimerExpaired = () => {
        process.env.NODE_ENV === 'production' ?
            API.finishQuestion(roomID) :
            console.log('Question Finished!')
    }

    return (
        <div className="RoomPage__container">
            <Time room={room} onTimerExpaired={onTimerExpaired} />

            <div className='RoomPage__side_container'>
                {room?.isQuizEnd ? (
                    <FinalScoreBoard room={room} />
                ) : (
                    <>
                        <LeftSide room={room} categories={categories} />
                        <RightSide room={room} />
                    </>
                )}
            </div>
        </div>
    )
}

export default HostView
