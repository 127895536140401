import { createTheme, SvgIconTypeMap, ThemeOptions } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import HideSourceIcon from '@mui/icons-material/HideSource';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import SecurityIcon from '@mui/icons-material/Security';
import { Traps } from "./types";

const defaultTheme = {
    typography: {
        fontFamily: 'Raleway, Arial',
        fontSize: 24,
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#e0a000',
        }
    },
};

export const hostTheme = createTheme({
    ...(defaultTheme as ThemeOptions),
    typography: {
        ...defaultTheme.typography,
        fontSize: 24,
    },
});

export const playerTheme = createTheme({
    ...(defaultTheme as ThemeOptions),
    typography: {
        ...defaultTheme.typography,
        fontSize: 18,
    },
});

export const maxQuestionNumber = 10;

export const timeCountSec = 60;

export const timeForAnswersShowUp = 15000;
// Repeat change in: components/TimeShort/styles.scss

export const trapList: Record<Traps, { name: Traps, title: string, icon: OverridableComponent<SvgIconTypeMap>, isPositive?: boolean }> = {
    rotateScreen: {
        name: 'rotateScreen',
        title: 'Odwrócony ekran',
        icon: ScreenRotationIcon,
    },
    hideLetters: {
        name: 'hideLetters',
        title: 'Znikające literki',
        icon: HideSourceIcon,
    },
    mirrorWord: {
        name: 'mirrorWord',
        title: 'Lustrzane słowa',
        icon: MobileScreenShareIcon,
    },
    clean: {
        name: 'clean',
        title: 'Wyczyść',
        icon: CleaningServicesIcon,
        isPositive: true,
    },
    fiftyFifty: {
        name: 'fiftyFifty',
        title: 'Pół na pół',
        icon: DragHandleIcon,
        isPositive: true,
    },
    shield: {
        name: 'shield',
        title: 'Nietykalny',
        icon: SecurityIcon,
        isPositive: true,
    },
}
