import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCuJTfw8rwtY3dM3P47w3yOEx_OSGE6JYs",
  authDomain: "goquiz-54e6d.firebaseapp.com",
  projectId: "goquiz-54e6d",
  storageBucket: "goquiz-54e6d.appspot.com",
  messagingSenderId: "638943113079",
  appId: "1:638943113079:web:d4b7edba3dd12f9540fb72",
  measurementId: "G-CF10WYMGGE",
  databaseURL: "https://goquiz-54e6d-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);

export const storage = getStorage(app);
