/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import QRCode from "react-qr-code";
import LinkIcon from '@mui/icons-material/Link';
import Face from '@mui/icons-material/Face';
import Backdrop from '@mui/material/Backdrop';
import { IRoom } from '../../../../../helpers/types'
import useAutoScroll from '../../../../../helpers/useAutoScroll';
import useAutoScrollVert from '../../../../../helpers/useAutoScrollVert';
import Player from './Player/Player';
import * as API from '../../../../../api/roomAPI';
import './styles.scss'

interface RightSideProps {
    room?: IRoom;
}

const RightSide: React.FC<RightSideProps> = ({ room }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const trapsContainerRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const { ID: roomID } = useParams() as { ID: string };
    useAutoScroll(listRef);
    useAutoScrollVert(trapsContainerRef);

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    // DEVELOP MODE
    // const testBtn = () => API.pushQuestionReq(roomID, room!);
    const onStartAgainQuizButton = () => {
        API.playQuizAgain(room!)
    }
    // DEVELOP MODE

    return (
        <div className='Right_side'>
            <div className='Right_side__container'>
                {!room?.currentQuestion && (
                    <div className='Right_side__container__connect_wrapper'>
                        <div className='Right_side__container__connect_wrapper__room_number'>
                            <LinkIcon />
                            Pokój nr:
                            <span className='Right_side__container__connect_wrapper__room_number__id'> {roomID}</span>
                        </div>
                        <QRCode
                            style={{ height: "auto", maxWidth: "70%", width: "100%" }}
                            value={window.location.href}
                            onClick={handleToggle}
                        />

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                            onClick={handleClose}
                        >
                            <QRCode value={window.location.href} />
                        </Backdrop>
                    </div>
                )}

                {/* {isHost && room?.usersIn?.length && ( */}
                <div className="Right_side__container__players_container">
                    <div className='Right_side__container__players_container__title'><Face /> Quizowicze:</div>

                    <div className="Right_side__container__players_container__players" ref={listRef}>
                        {room?.usersIn?.sort((a, b) => b.points - a.points)?.map((user) => <Player key={user.id} user={user} room={room} />)}
                    </div>

                    <br />
                    {process.env.NODE_ENV !== 'production' && <button onClick={onStartAgainQuizButton}>RESTART</button>}
                </div>
                {/* )} */}
            </div>
        </div>
    )
}

export default RightSide
