/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import cx from 'classnames';
import Button from '@mui/material/Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Time from '../../../components/Time/Time';
import { IQuestion, IRoom } from '../../../helpers/types';
import logo from '../../../assets/logo.png';
import SliderAvatars from '../../../components/SliderAvatars/SliderAvatars';
import ChooseTrapsSnack from '../../../components/ChooseTrapsSnack/ChooseTrapsSnack';
import TrapsNotification from '../../../components/TrapsNotification/TrapsNotification';
import arrow from '../../../assets/arrow1.png';
import TimeShort from '../../../components/TimeShort/TimeShort';
import * as API from '../../../api/roomAPI';
import * as H from '../helpers';
import * as C from '../../../helpers/constants';
import S from './styles.module.scss';

interface PlayerViewProps {
    room?: IRoom;
    questions?: IQuestion[];
}

const PlayerView: React.FC<PlayerViewProps> = ({ room, questions }) => {
    const { ID: roomID } = useParams<{ ID: string }>();
    const { state } = useLocation<{ user: string }>();
    const userID = state?.user;

    const user = useMemo(() => room?.usersIn.find(user => user.id === userID)!, [room?.usersIn, userID]);

    const onChosenAnswer = (answer: string) => () => {
        API.pushAnswerReq(answer, roomID!, userID);
    }

    const onSetReadyButton = () => {
        API.setReadyPlayer(userID, room!);
    }

    const playerAnswerValue = room?.currentQuestion?.userAnswers?.[userID]?.name;
    const isReady = H.getUserFromUsersIn(userID, room)?.isReady;

    // TRAPS
    const isMirrorWordTrap = H.hasUserThisTrap(userID, C.trapList.mirrorWord.name, room);
    const isHideLettersTrap = H.hasUserThisTrap(userID, C.trapList.hideLetters.name, room);
    const isRotateTrap = H.hasUserThisTrap(userID, C.trapList.rotateScreen.name, room);
    const isFiftyFiftyTrap = H.hasUserThisTrap(userID, C.trapList.fiftyFifty.name, room);

    const fillAnswerWithTraps = (answer: string) => {
        let answerResults = answer;

        if (isMirrorWordTrap) {
            answerResults = answerResults.split('').reverse().join('');
        }
        if (isHideLettersTrap && answerResults.length >= 4) {
            answerResults = answerResults.split('').map((char, n) => n % 3 === 0 ? ' ' : char).join('');
        }
        return answerResults;
    }

    const isNotOptionFirstOrSecond = (answer: string, room: IRoom) => (
        answer !== room.questions?.[room.currentQuestion.qNumber].answers[0] && answer !== room.questions?.[room.currentQuestion.qNumber].answers[1]
    )

    return (
        <div className={cx(S.Container, { [S.IsRotate]: isRotateTrap })}>
            <Time room={room} isPlayerAnswered={!!playerAnswerValue} />

            {room && !room.currentQuestion && (
                <div className={S.WaitingContainer}>
                    <div className={S.SliderText}>{user.name} masz chwilę ? Wybierz swoją postać...</div>
                    <SliderAvatars room={room} user={user} />

                    {isReady ? (
                        <div className={S.Text}>...rozgrzej nadgarstki za chwilę startujemy 🛫</div>
                    ) : (
                        <Button
                            className={S.ReadyButton}
                            variant="outlined"
                            color={'success'}
                            size="large"
                            onClick={onSetReadyButton}
                        >
                            <TaskAltIcon sx={{ color: 'green' }} />
                            Gotowy ! 😎
                        </Button>
                    )}
                </div>
            )}

            {room?.currentQuestion?.answers && (
                <div className={S.OptionsContainer}>
                    {room?.currentQuestion?.answers?.map((answer) => (
                        <Button
                            key={answer}
                            className={S.Option}
                            variant="outlined" color={playerAnswerValue === answer ? 'info' : 'primary'} size="large"
                            onClick={onChosenAnswer(answer)}
                            disabled={room.currentQuestion.isFinished || (isFiftyFiftyTrap && isNotOptionFirstOrSecond(answer, room))}
                            style={room.currentQuestion.isFinished
                                && answer === room.questions?.[room.currentQuestion.qNumber].answers[0]
                                ? { border: '1px solid green' } : {}}
                        >
                            {fillAnswerWithTraps(answer)}
                        </Button>
                    ))}
                </div>
            )}

            {room && room.currentQuestion && playerAnswerValue && (
                <div className={cx(S.OptionsInfo, 'animate__animated animate__bounce')}>
                    Mamy twoją odpowiedź, ale czy na pewno jesteś jej pewn{H.getGender(user.name) === "W" ? 'a' : 'y'} ? 🙄
                </div>
            )}

            <div className={S.Logo}>
                <img src={logo} alt="logo" width={200} />
            </div>

            {room && room.currentQuestion && !room?.currentQuestion?.answers && (
                <>
                    <div className={S.OptionsInfoWrapper}>
                        <TimeShort />
                        <div className={cx(S.OptionsInfoShowUp)}>
                            Za chwilę pojawią się odpowiedzi
                        </div>
                    </div>
                    <div className={cx(S.OptionsInfoTraps, 'animate__animated animate__bounce animate__delay-5s')}>
                        Wykorzystaj ten czas na podłożenie komuś miny <div>😵</div>
                        <img className={cx(S.Arrow, { [S.IsArrowHidden]: window.innerHeight <= 550 })} src={arrow} />
                    </div>
                </>
            )}

            {/* <Traps userID={userID} room={room} /> */}

            {room?.currentQuestion && !room?.currentQuestion.isFinished && <ChooseTrapsSnack userID={userID} room={room} />}

            <TrapsNotification userID={userID} room={room} />
        </div>
    )
}

export default PlayerView
