/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ref, onValue } from 'firebase/database'
import { db } from '../firebase'
import { ICategories, IRoom } from './types'

export const useFetch = () => {
    // const [users, setUsers] = useState<{ [key: string]: { usersIn: string[] } }>({});
    const [room, setRoom] = useState<IRoom>()
    const [categories, setCategories] = useState<ICategories[]>()
    const { ID: roomID } = useParams<{ ID: string }>();

    const fetchRoom = () => onValue(ref(db, `rooms/${roomID}`), (snapshot) => {
        const data = snapshot.val()
        setRoom(data)
    })

    const fetchCategories = () => onValue(ref(db, `categoryList`), (snapshot) => {
        const data = snapshot.val()
        setCategories(data)
    })

    useEffect(() => {
        const unsubscribeRoom = fetchRoom();
        const unsubscribeCategories = fetchCategories();

        return () => {
            unsubscribeRoom();
            unsubscribeCategories();
        }
    }, [])

    return ({ room, categories })
}
