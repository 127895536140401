import { IRoom, Traps } from "../../helpers/types";

export const isHost = (userID: string, hostUserID?: string) => userID === hostUserID;

export const getGender = (userName: string) => userName?.slice(-1) === 'a' ? "W" : "M";

export const isPlayerIdAnswered = (room: IRoom, userID: string) => !!room?.currentQuestion?.userAnswers?.[userID];

export const getUserFromUsersIn = (userID: string, room?: IRoom) => room?.usersIn?.find(user => user.id === userID);

export const hasUserThisTrap = (userID: string, trapName?: Traps, room?: IRoom) => !!trapName && !!room?.currentQuestion?.userTraps?.[userID]?.[trapName];