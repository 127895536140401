/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import cx from 'classnames';
import Button from '@mui/material/Button'
import FlagIcon from '@mui/icons-material/Flag';
import { ICategories, IRoom } from '../../../../../helpers/types'
import logo from '../../../../../assets/logo.png';
import useUserAnswerPoints from '../../../../../helpers/useUserAnswerPoints';
import QuestionScoreBoard from '../../../../../components/QuestionScoreBoard/QuestionScoreBoard';
import * as API from '../../../../../api/roomAPI';
import './styles.scss'

interface LeftSideProps {
    room?: IRoom;
    categories?: ICategories[];
}

const LeftSide: React.FC<LeftSideProps> = ({ room, categories }) => {
    const { ID: roomID } = useParams() as { ID: string };
    const [categoryChosen, setCategoryChosen] = useState<string>();

    const { userWithPoints } = useUserAnswerPoints(roomID, room);

    const onChosenCategory = (category: string) => () => {
        setCategoryChosen(category)
        API.pushCategoryWithQuestions(roomID!, category);
    }

    const onStartQuizButton = () => {
        API.pushQuestionReq(roomID!, room!);
    }

    const isEveryPlayerReady = room?.usersIn?.every(user => user.isReady);

    return (

        <div className={cx('Left_side', { 'isQuizStared': room?.currentQuestion })}>
            <div className='Left_side__container'>
                {!room?.currentQuestion ? (
                    <>
                        <div>Wybierz kategorie: </div>
                        <div className='Left_side__container__category_container'>
                            {categories?.map(category =>(
                                    <Button
                                        key={category.name}
                                        size="large"
                                        variant="outlined"
                                        color={categoryChosen === category.name ? 'info' : 'primary'}
                                        onClick={onChosenCategory(category.name)}
                                    >
                                        {category.title}
                                    </Button>
                                )
                            )}
                        </div>
                        <Button variant="outlined" color="success" size="large" startIcon={<FlagIcon />}
                            disabled={!(room?.usersIn && room?.usersIn?.length >= 2 && room.questions && isEveryPlayerReady)} onClick={onStartQuizButton}
                        >
                            Startujemy
                        </Button>
                    </>
                ) : (
                    room.currentQuestion.isFinished ? (
                        <QuestionScoreBoard
                            userWithPoints={userWithPoints}
                            usersIn={room.usersIn}
                        />
                    ) : (
                        <>
                            {room?.currentQuestion?.imageUrl && (
                                <div className='Left_side__container__image_container'>
                                    <img className='Left_side__container__image_container__image' src={room?.currentQuestion?.imageUrl} alt="questionImage" />
                                </div>
                            )}
                            <div className={`${!!room.currentQuestion.imageUrl ? 'Left_side__container__question_with_image' : 'Left_side__container__question'}`}>{room?.currentQuestion?.question}</div>
                        </>
                    )
                )}

                <img className='Left_side__container__logo' src={logo} alt="logo" width={200} />
            </div>
        </div>
    )
}

export default LeftSide
